@import "../pfd";

// News slider optimization because of the bigger headlines
.news-slider .news-teaser__content {
	height: 25rem;

	@include media-breakpoint-up(m) {
		height: 16.25rem;
	}

	@include media-breakpoint-up(l) {
		height: 19rem;
	}
}

.news-slider .slick-dots {
	bottom: calc(25rem - 40px);

	@include media-breakpoint-up(m) {
		bottom: calc(16.25rem - 40px);
	}

	@include media-breakpoint-up(l) {
		bottom: calc(19rem - 40px);
	}
}

.nav__stickybar {
	background-color: color("yellow", "base");
	color: $primary-color;

	.btn-light {
		background-color: $primary-color;

		i {
			color: color("shades", "white");
		}
	}

	a {
		color: $primary-color;
	}
}

.sidenav .sidenav-close {
	background-color: color("yellow", "base");
	color: $primary-color;
}